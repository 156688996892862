blogsWrapper {
  position: relative;
  left: 90px;
  height: 100%;
  width: calc(100% - 290px);
  display: inline-flex;
  flex-direction: column;
  padding: 150px 100px;

  blogsBigText {
    color: var(--131748, #131748);
    font-family: Figtree;
    font-size: 56px;
    font-style: normal;
    font-weight: 600;
    line-height: 58px;
    text-transform: uppercase;
    border-bottom: solid 1px #c0c0c0;
    padding-bottom: 40px;
  }
  blogGrid {
    width: 100%;
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    row-gap: 40px;
    flex-wrap: wrap !important;
  }
}

@media screen and (max-width: 768px) {
  blogsWrapper {
    position: relative;
    left: unset;
    top: 90px;
    height: 100%;
    width: calc(100% - 30px);
    display: inline-flex;
    flex-direction: column;
    padding: 50px 15px;
    margin-bottom: 90px;

    blogsBigText {
      color: var(--131748, #131748);

      font-family: Figtree;
      font-size: 46px;
      font-style: normal;
      font-weight: 600;
      line-height: 50px; /* 108.696% */
      text-transform: uppercase;
      padding-bottom: 40px;
    }
    blogGrid {
      width: 100%;
      padding-top: 40px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 30px;
      row-gap: 40px;
      flex-wrap: wrap !important;
    }
  }
}
