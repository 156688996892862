.blogCard {
  display: flex;
  width: calc(33% - 17px) !important;
  height: fit-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  text-decoration: none;
  min-width: 350px;
  text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    h5 {
      color: var(--131748, #131748);

      /* Heading 5 */
      font-family: Figtree;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 118.182% */
    }
    date {
      color: var(--31388F, #31388f);

      /* Accent */
      font-family: Figtree;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.98px; /* 157% */
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  img {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) {
  .blogCard {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    text-decoration: none;
    min-width: 100%;
    text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      h5 {
        color: var(--131748, #131748);

        /* Heading 5 */
        font-family: Figtree;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 118.182% */
      }
      date {
        color: var(--31388F, #31388f);

        /* Accent */
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21.98px; /* 157% */
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }
    img {
      width: 100%;
      height: auto;
      max-height: 300px;
      object-fit: cover;
    }
  }
}
