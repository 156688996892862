footerWrapper {
  position: relative;
  width: calc(100% - 290px);
  left: 90px;
  display: flex;
  flex-direction: column;
  background: var(--131748, #131748);
  padding: 80px 100px 29px 100px;
  gap: 160px;
  top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      svg {
        width: 243.341px;
        height: 41px;
      }

      button {
        display: flex;
        padding: 17px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: var(--F1F4F6, #f1f4f6);
        text-align: center;
        font-family: "Inter Tight";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.6px;
        text-transform: uppercase;
        background: transparent;
        border: 1px solid rgba(241, 244, 246, 0.5);
        &:hover {
          color: #111541;
          background: #f1f4f6;
          transition: 0.5s;
          cursor: pointer;
          border: 1px solid transparent;
        }
      }
    }

    right {
      display: flex;
      align-items: flex-start;
      gap: 90px;

      a {
        max-width: 191px;
        color: var(--F1F4F6, #f1f4f6);

        /* Heading 6 */
        font-family: Figtree;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 115.789% */
        text-decoration: none;
        transition: 0.8s;

        p {
          color: var(--F1F4F6, #f1f4f6);

          /* Paragraph */
          font-family: Figtree;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px; /* 153.333% */
        }
        &:hover {
          color: #dd7d47;
          transition: 0.1s;
        }
      }

      navigation {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }
  }
  bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid rgba(241, 244, 246, 0.2);
    p {
      color: var(--F1F4F6, #f1f4f6);
      opacity: 0.7;
      /* Paragraph */
      font-family: Figtree;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30.666666666666664px; /* 153.333% */
    }

    a {
      color: var(--F1F4F6, #f1f4f6);
      margin-left: 64px;
      opacity: 0.7;
      /* Paragraph */
      font-family: Figtree;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30.666666666666664px; /* 153.333% */
      transition: 0.8s;
      text-decoration: none;

      &:hover {
        color: #dd7d47;
        transition: 0.1s;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  footerWrapper {
    position: relative;
    left: unset;
    background: var(--131748, #131748);
    display: flex;
    width: calc(100% - 30px);
    padding: 60px 15px 30px 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 160px;
    top {
      display: flex;
      width: fit-content;
      justify-content: space-between;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 60px;
      left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        svg {
          width: 243.341px;
          height: 41px;
        }

        button {
          display: flex;
          padding: 17px 50px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: var(--F1F4F6, #f1f4f6);
          text-align: center;
          font-family: "Inter Tight";
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 133.333% */
          letter-spacing: 0.6px;
          text-transform: uppercase;
          background: transparent;
          border: 1px solid rgba(241, 244, 246, 0.5);
          &:hover {
            color: #111541;
            background: #f1f4f6;
            transition: 0.5s;
            cursor: pointer;
            border: 1px solid transparent;
          }
        }
      }

      right {
        display: flex;
        align-items: flex-start;
        gap: 90px;
        flex-direction: column;
        a {
          max-width: 191px;
          color: var(--F1F4F6, #f1f4f6);

          /* Heading 6 */
          font-family: Figtree;
          font-size: 19px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 115.789% */
          text-decoration: none;
          transition: 0.8s;

          p {
            color: var(--F1F4F6, #f1f4f6);

            /* Paragraph */
            font-family: Figtree;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px; /* 153.333% */
          }
          &:hover {
            color: #dd7d47;
            transition: 0.1s;
          }
        }

        navigation {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }
    bottom {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      border-top: 1px solid rgba(241, 244, 246, 0.2);
      flex-direction: column;
      gap: 10px;
      width: 100%;
      p {
        color: var(--F1F4F6, #f1f4f6);

        /* Mobile/Paragraph */
        font-family: Figtree;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: fit-content;
      }
      legalStuff {
        display: flex;
        align-items: flex-start;
        gap: 48px;
      }
      a {
        color: var(--F1F4F6, #f1f4f6);
        margin-left: unset;
        opacity: 0.7;

        font-size: 15px;
        line-height: 24px;
        transition: 0.8s;
        text-decoration: none;

        &:hover {
          color: #dd7d47;
          transition: 0.1s;
        }
      }
    }
  }
}
