aboutWrapper {
  position: relative;
  left: 90px;
  width: calc(100% - 90px);
  display: inline-flex;
  flex-direction: column;
  hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    flex-shrink: 0;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url(../../assets/aboutPageHero.jpg) lightgray no-repeat;
    background-size: cover;
    padding: 0 0 0 86.66666666666666px;
    color: #f1f4f6;

    textContent {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      acc {
        svg {
          width: 8px;
          height: 8px;
        }
      }
      h1 {
        max-width: 500px;
      }
    }

    blueBoxes {
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      width: 490px;

      box {
        padding: 40px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: rgba(19, 23, 72, 0.7);

        &:nth-child(even) {
          background-color: rgba(49, 56, 143, 0.7);
        }
      }
    }
  }

  section2 {
    display: flex;
    flex-direction: column;
    gap: 100px;
    padding: 80px 100px;
    color: #131748;

    titleContent {
      display: flex;
      width: 450px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      acc {
        color: #131748;
        svg {
          width: 8px;
          height: 8px;
        }
      }
    }

    cards {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 43px;
      align-self: stretch;

      card {
        max-width: 260px;

        img {
          width: 70px;
          height: 70px;
          margin-bottom: 30px;
        }
        h6 {
          margin-bottom: 15px;
        }
      }
    }
  }

  section3 {
    display: inline-flex;
    padding: 70px 100px;
    flex-direction: column;
    align-items: center;
    background-color: #111541;
    color: #f1f4f6;

    acc {
      margin-bottom: 15px;
      svg {
        width: 8px;
        height: 8px;
      }
    }

    h3 {
      text-align: center;
      margin-bottom: 40px;
      max-width: 550px;
    }
    videoWrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      video {
        width: 100%;
      }
      svg {
        position: absolute;
        width: 100px;
        height: 100px;
        flex-shrink: 0;
      }
    }

    @keyframes playing {
      0% {
        transform: scale(1);
        opacity: 1;
      }

      100% {
        transform: scale(2);
        opacity: 0;
      }
    }

    @keyframes paused {
      0% {
        transform: scale(2);
        opacity: 0;
      }

      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
  section4 {
    display: inline-flex;
    padding: 80px 100px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex-direction: row;
    img {
      width: 50%;
      object-fit: cover;
      flex-shrink: 0;
    }

    textContent {
      width: 50%;
      display: flex;
      width: 530px;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
    }
  }
  section5 {
    background-color: #f1f4f6;
    display: flex;
    padding: 80px 100px;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
    color: #131748;

    textContent {
      display: block;

      acc {
        margin-bottom: 10px;
        color: #131748;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }
    products {
      display: flex;
      gap: 80px;

      product {
        display: block;
        img {
          height: 200px;
          width: 100%;
          object-fit: cover;
          margin-bottom: 20px;
        }
        h6 {
          margin-bottom: 10px;
        }
      }
    }
  }
}

// @media screen and (max-width: 1080px) {
//   aboutWrapper {
//     padding: 0;
//     width: 100vw;

//     hero {
//       position: relative;
//       padding: 125px 0 0 15px;
//       background-size: 210lvh;
//       align-items: flex-start;

//       blueBoxes {
//         position: absolute;
//         width: 300px;
//         right: 0;

//         box {
//           padding: 20px;
//         }
//       }
//     }

//     section2 {
//       padding: 40px 15px;

//       cards {
//         flex-direction: column;
//         width: 100%;

//         card {
//           width: 100%;
//         }
//       }
//     }

//     section3 {
//       padding: 40px 15px;

//       videoWrapper {
//         svg {
//           width: 70px;
//           height: 70px;
//         }
//       }
//     }

//     section4 {
//       padding: 30px 15px;
//       flex-direction: column;
//       gap: 30px;

//       img {
//         width: 100%;
//       }

//       textContent {
//         width: 100%;
//       }
//     }

//     section5 {
//       padding: 60px 15px;

//       textContent {
//         acc {
//           gap: 10px;
//           margin-bottom: 10px;
//           svg {
//             width: 8px;
//             height: 8px;
//           }
//         }
//       }

//       products {
//         flex-direction: column;
//       }
//     }
//   }
// }

@media screen and (max-width: 768px) {
  aboutWrapper {
    position: relative;
    left: unset;
    top: 70px;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 90px;
    hero {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100vh;
      flex-shrink: 0;
      flex-direction: column;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0.4) 100%
        ),
        url(../../assets/aboutPageHero.jpg) lightgray no-repeat;
      background-size: cover;
      padding: 50px 15px;
      color: #f1f4f6;

      textContent {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        acc {
          svg {
            width: 8px;
            height: 8px;
          }
        }
        h1 {
          max-width: 500px;
        }
      }

      blueBoxes {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        width: 300px;

        box {
          display: flex;
          padding: 20px;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;
          background-color: rgba(19, 23, 72, 0.7);
          h4 {
            color: #fff;

            /* Mobile/Heading 4 */
            font-family: Figtree;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 118.182% */
          }
          p {
            color: #fff;

            /* Mobile/Paragraph */
            font-family: Figtree;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 160% */
          }

          &:nth-child(even) {
            background-color: rgba(49, 56, 143, 0.7);
          }
        }
      }
    }

    section2 {
      display: flex;
      flex-direction: column;
      gap: 100px;
      padding: 40px 15px;
      color: #131748;

      titleContent {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        acc {
          color: #131748;
          svg {
            width: 8px;
            height: 8px;
          }
        }
      }

      cards {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 43px;
        align-self: stretch;
        flex-direction: column;
        width: 100%;
        card {
          max-width: unset;
          width: 100%;

          img {
            width: 70px;
            height: 70px;
            margin-bottom: 30px;
          }
          h6 {
            margin-bottom: 15px;
          }
        }
      }
    }

    section3 {
      display: inline-flex;
      padding: 40px 15px;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      background-color: #111541;
      color: #f1f4f6;

      acc {
        margin-bottom: 10px;
      }

      h3 {
        text-align: center;
        max-width: unset;
      }
      videoWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        video {
          width: 100%;
        }
        svg {
          position: absolute;
          width: 70px;
          height: 70px;
          flex-shrink: 0;
        }
      }

      @keyframes playing {
        0% {
          transform: scale(1);
          opacity: 1;
        }

        100% {
          transform: scale(2);
          opacity: 0;
        }
      }

      @keyframes paused {
        0% {
          transform: scale(2);
          opacity: 0;
        }

        100% {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
    section4 {
      display: inline-flex;
      padding: 30px 15px;
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      img {
        width: 100%;
        object-fit: cover;
        flex-shrink: 0;
      }

      textContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
      }
    }
    section5 {
      background-color: #f1f4f6;
      display: flex;
      padding: 60px 15px;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      color: #131748;

      textContent {
        display: block;

        acc {
          margin-bottom: 10px;
          color: #131748;
          svg {
            width: 10px;
            height: 10px;
          }
        }
      }
      products {
        display: flex;
        gap: 80px;

        flex-direction: column;
        product {
          display: block;
          img {
            height: 200px;
            width: 100%;
            object-fit: cover;
            margin-bottom: 20px;
          }
          h6 {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
