galleryWrapper {
  padding: 133.33333333333331px 133.33333333333331px 133.33333333333331px
    226.66666666666666px;
  display: block;
  color: #f1f4f6;
  background-color: #31388f;

  textContent {
    display: block;
    margin-bottom: 80px;
    acc {
      margin-bottom: 6.666666666666666px;
      svg {
        width: 10.666666666666666px;
        height: 10.666666666666666px;
      }
    }
  }

  gallery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
gap:5px;
    imageWrapper {
      display: block;
      width: calc(33.333% - 5px);
      aspect-ratio: 9/12;
      margin-bottom: -4px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }

    screenImgCover {
      transition: 0.1s;
      z-index: 100000;
      position: fixed;
      display: flex;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      justify-content: center;
      align-items: center;

      background {
        z-index: 1;
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: #000;
        opacity: 0.5;
      }

      img {
        // width: 80%;
        height: 80%;
        aspect-ratio: 9/12;
        z-index: 2;
        object-fit: cover;
        @media screen and (max-width: 768px) {
          width: 80%;
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  galleryWrapper {
    padding: 0;

    textContent {
      padding: 125px 15px 50px 15px;
      margin: 0;
    }

    gallery {
      width: calc(100% - 40px);
      margin: 0 20px 0 20px;
      padding-bottom: 100px;

      img {
        width: 50%;
      }
    }
  }
}


.h2{
  margin-bottom: 45px;
}
.h4{
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .h2{
    margin-bottom: 45px;
    margin-left: 15px;
  }
  .h4{
    margin-bottom: 20px;
    margin-left: 15px;
  }
}