.jobsWrapper {
  padding-left: 93.33309999999999px;
  display: block;

  .hero {
    color: #f1f4f6;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 50px 100px;
    background-color: #31388f;
    height: 400px;

    .acc {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--F1F4F6, #f1f4f6);

      /* Accent */
      font-family: Figtree;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.98px; /* 157% */
      letter-spacing: 2px;
      text-transform: uppercase;
      svg {
        width: 10px;
        height: 10px;
      }
    }
    h1 {
      text-transform: uppercase;
    }
  }

  .section2 {
    padding: 80px 100px;
    display: block;
  }
  .thankYou {
    width: calc(100% - 90px);
    padding: 80px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    height: 50vh;
    text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 11px;
      align-self: stretch;
      h4 {
        color: var(--131748, #131748);
        text-align: center;

        /* Heading 4 */
        font-family: Figtree;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 114.286% */
      }
      p {
        color: var(--31388F, #31388f);
        text-align: center;

        /* Paragraph */
        font-family: Figtree;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px; /* 153.333% */
      }
    }
    a {
      width: fit-content;
      display: flex;
      padding: 17px 50px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: 1px solid rgba(241, 244, 246, 0.5);
      background: var(--131748, #131748);
      color: var(--F1F4F6, #f1f4f6);
      text-align: center;
      font-family: "Inter Tight";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.6px;
      text-transform: uppercase;
      text-decoration: none;
      transition: all 0.1s;

      &:hover {
        background: #f1f4f6;
        color: var(--131748, #131748);
        border: solid 1px #131748;
        transition: all 0.5s;
      }
    }
  }
  .applicationWrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 90px 300px;

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 60px;
    }
    .titleContent {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .acc {
        display: flex;
        align-items: center;
        gap: 10px;

        /* Accent */
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21.98px; /* 157% */
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #131748;
        svg {
          width: 10px;
          height: 10px;
        }
      }
    }

    .entriesSection {
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 20px;
      h5 {
        margin-bottom: -5px;
      }

      input {
        width: 100%;
        display: flex;
        padding: 15px 0px;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;

        border-bottom: 1px solid var(--131748, #131748);
      }
    }

    button {
      cursor: pointer;
      display: flex;
      padding: 17px 50px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      color: var(--F1F4F6, #f1f4f6);
      text-align: center;
      font-family: "Inter Tight";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.6px;
      text-transform: uppercase;
      border: 1px solid rgba(241, 244, 246, 0.5);
      background: var(--131748, #131748);
    }
  }
}

.jobWrapper {
  padding: 30px 0;
  &:first-child {
    border-top: 1px solid #c5c8d3;
  }
  border-bottom: 1px solid #c5c8d3;

  display: flex;
  justify-content: space-between;
  .left {
    .acc {
      display: flex;
      align-items: center;
      gap: 10px;

      /* Accent */
      font-family: Figtree;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.98px; /* 157% */
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: #31388f;
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
  .right {
    width: 470px;
    display: block;

    button {
      cursor: pointer;
      margin-top: 20px;
      display: flex;
      padding: 15px 60px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      color: var(--F1F4F6, #f1f4f6);
      text-align: center;
      font-family: "Inter Tight";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.6px;
      text-transform: uppercase;
      background: var(--131748, #131748);
    }
  }
}

@media screen and (max-width: 768px) {
  .jobsWrapper {
    padding: 0;

    .hero {
      height: 215px;
      padding: 128px 15px 25px 15px;
    }

    .section2 {
      padding: 50px 15px;
    }
    .thankYou {
      padding: 50px 15px;
      width: calc(100% - 30px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      align-self: stretch;
      height: 50vh;
      text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 11px;
        align-self: stretch;
        h4 {
          color: var(--131748, #131748);
          text-align: center;

          /* Mobile/Heading 4 */
          font-family: Figtree;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 118.182% */
        }
        p {
          color: var(--31388F, #31388f);
          text-align: center;

          /* Mobile/Paragraph */
          font-family: Figtree;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 160% */
        }
      }
      a {
        display: flex;
        padding: 17px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: 1px solid rgba(241, 244, 246, 0.5);
        background: var(--131748, #131748);
        color: var(--F1F4F6, #f1f4f6);
        text-align: center;
        font-family: "Inter Tight";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.6px;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.1s;

        &:hover {
          background: #f1f4f6;
          color: var(--131748, #131748);
          border: solid 1px #131748;
          transition: all 0.5s;
        }
      }
    }
    .applicationWrapper {
      padding: 60px 15px;
    }
  }

  .jobWrapper {
    flex-direction: column;

    .right {
      width: 100%;
    }
  }
}

/* Error input field style */
.errorInput {
  border-bottom: 1px solid #ff4d4d !important; /* Red border for error inputs */
}

.customFileInput.errorInput {
  border-bottom: 1px solid #ff4d4d !important;
}

.fileInputContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.customFileInput {
  display: flex;
  padding: 15px 0px;
  align-items: center;
  gap: 10px;
  background: transparent;
  border-bottom: 1px solid var(--Cod-Gray, #1b1b1b);
  color: var(--Divider-Color, #fff);

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px; /* 161.111% */
  display: flex;
  justify-content: space-between;
  p {
    color: #131748;
  }
}
