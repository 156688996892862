furnitureWrapper {
  background-color: #f1f4f6;
  display: block;
  padding: 133.33333333333331px 106.66666666666666px 133.33333333333331px 200px;
  swiperWrapper {
    margin-bottom: 60px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    leftArrow,
    rightArrow {
      text-shadow: none;
      color: transparent;
      background-color: transparent;
      color: #131748 !important;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: transparent;
      border: solid 1px #131748;
      transition: all 0.1s linear;
      svg {
        width: 15px;
        height: 15px;
        fill: #131748;
      }
      &:hover {
        background-color: #131748;
        svg {
          path {
            fill: white !important;
          }
        }
      }
    }

    leftArrow {
      transform: rotate(180deg);
    }

    .swiper {
      width: 100% !important;
      height: auto;
      display: flex !important;
      gap: 30px !important;
      .swiperSlide {
        width: fit-content;
        .sliderImage {
          width: 100%;
        }
      }
    }
  }
  textContent {
    display: block;
    margin-bottom: 66.66666666666666px;
    color: #131748;
    acc {
      color: #131748;
      margin-bottom: 6.666666666666666px;
      svg {
        stroke-width: 2.6666666666666665px;
        width: 10.666666666666666px;
        height: 10.666666666666666px;
      }
    }
    h1 {
      text-transform: uppercase;
      max-width: 500px;
    }
  }

  logosWrapper {
    background-color: #f1f4f6;
    display: inline-flex;
    padding: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    gap: 30px;

    img {
      aspect-ratio: 0.88607594936;
      width: calc(16% - 30px);
      object-fit: cover;
      border: 1px solid rgba(49, 56, 143, 0.2);
    }
  }
}

@media screen and (max-width: 768px) {
  furnitureWrapper {
    background-color: #f1f4f6;

    position: relative;
    top: 70px;
    display: block;
    padding: 50px 15px;
    margin-bottom: 70px;
    textContent {
      display: block;
      margin-bottom: 48px;
      color: #131748;
      acc {
        color: #131748;
        margin-bottom: 5px;
        svg {
          width: 8px;
          height: 8px;
        }
      }
      h1 {
        text-transform: uppercase;
        max-width: 100%;
      }
    }

    logosWrapper {
      background-color: #f1f4f6;
      display: inline-flex;
      padding: 00px;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      align-items: flex-start;
      gap: 30px;

      img {
        flex: unset;
        aspect-ratio: 0.88607594936;
        width: calc(50% - 20px);
        object-fit: cover;
        border: 1px solid rgba(49, 56, 143, 0.2);
      }
    }
  }
}
