headerWrapper {
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 66.66666666666666px;
  height: calc(100lvh - 80px);
  background-color: #131748;
  padding: 26.666666666666664px 13.333333333333332px 53.33333333333333px
    13.333333333333332px;

  svg {
    cursor: pointer;
    width: 66.66666666666666px;
    height: 73.33333333333333px;
  }

  shelfHandle {
    display: flex;
    flex-direction: column;
    gap: 10.666666666666666px;
    width: 53.33333333333333px;
    cursor: pointer;
    transition: 0.8s;
    &:hover {
      transition: 0.05s;
      gap: 7.66666666666px;
      line {
        transition: 0.05s;
        height: 3px;
        background-color: #dd7d47;
      }
    }

    line {
      transition: 0.8s;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #f1f4f6;
    }
  }

  languageSelection {
    display: flex;
    flex-direction: column;
    gap: 26.666666666666664px;
    p {
      color: var(--F1F4F6, #f1f4f6);
      font-family: "Inter Tight";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 100% */
      cursor: pointer;
    }
  }

  shelf {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: -1;
    width: 574px;
    height: 100vh;
    left: -801px;
    top: 0;
    padding: 80px 80px 80px 146.66666666666666px;
    gap: 24px;
    background-color: #131748;
    transition: left 0.3s ease-in-out;
    a {
      align-self: stretch;
      color: #f1f4f6;
      text-decoration: none;
      font-family: Figtree;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 54.666666666666664px; /* 113.889% */
      transition: 0.8s;
      &:hover {
        transition: 0.1s;
        color: #dd7d47;
      }
    }
    &.active {
      left: 0;
    }
  }

  gradient {
    transition: opacity 0.2s;
    position: fixed;
    width: 100lvw;
    height: 100lvh;
    background-color: #000;
    top: 0;
    z-index: -3;
    left: 0;
  }
}

@media screen and (max-width: 768px) {
  headerWrapper {
    padding: 10px 15px;
    width: calc(100lvw - 30px);
    height: 55px;
    flex-direction: row;

    svg {
      width: 50px;
      height: 55px;
    }

    shelfHandle {
      width: 40px;
      gap: 7px;
    }

    languageSelection {
      position: absolute;
      flex-direction: row;
      right: 80px;
    }

    shelf {
      width: 100%;
      max-width: 100%;
      padding: 100px 20px;
      a {
        align-self: stretch;
        color: #f1f4f6;
        text-decoration: none;
        font-family: Figtree;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 113.889% */
        transition: 0.8s;
        &:hover {
          transition: 0.1s;
          color: #dd7d47;
        }
      }
    }
  }
}
