homeWrapper {
  position: relative;
  left: 90px;
  width: calc(100% - 90px);
  display: inline-flex;
  flex-direction: column;

  hero {
    position: relative;

    height: 100vh;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url(../../assets/homePageHero.jpg) lightgray 50% / cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 133.333px;

    heroContent {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 6.66665px;
      acc {
        display: flex;
        gap: 13.3333px;
        align-items: center;
        color: #fff;
      }
      h1 {
        color: #fdfdfd;
        font-family: Figtree;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 46px; /* 115% */
        text-transform: uppercase;
        width: 637px;
      }
      button {
        margin-top: 19.99995px;
        padding: 22.66661px 66.6665px;
        background: var(--31388F, #31388f);
        cursor: pointer;
        color: var(--F1F4F6, #f1f4f6);
        text-align: center;
        font-family: "Inter Tight";
        font-size: 15.999959999999998px;
        font-style: normal;
        font-weight: 700;
        line-height: 21.33328px; /* 133.333% */
        letter-spacing: 0.7999979999999999px;
        text-transform: uppercase;
      }
    }
  }
  section2 {
    position: relative;

    display: flex;
    padding: 100px;
    flex-direction: column;
    align-items: center;
    // gap: 30px;
    point {
      border-bottom: 1px solid #c2c4e1;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      padding: 30px 0px;
      width: 100%;
      left {
        width: 200px;
        flex-shrink: 0;
        align-items: center;
        display: flex;
        gap: 20px;
      }
      p {
        width: 100%;
        max-width: 450px;
      }
      &:first-child {
        border-top: 1px solid #c2c4e1;
      }
    }
  }
  section3 {
    position: relative;

    display: flex;
    flex-direction: column;
    background-color: #f1f4f6;
    img {
      height: 650px;
      object-fit: cover;
    }

    reviewsWrapper {
      display: flex;
      padding: 100px 150px;
      flex-direction: column;
      align-items: flex-start;
      gap: 39px;

      h3 {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
      }

      theReviews {
        display: flex;
        align-items: flex-start;
        gap: 70px;
        align-self: stretch;
        svg {
          flex-shrink: 0;
        }
      }

      .swiperWrapper {
        position: relative;
        overflow-x: hidden;

        .review {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 30px;
          width: 100% !important;
          reviewText {
            color: var(--131748, #131748);
            font-family: Figtree;
            font-size: 23px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 130.435% */
          }

          reviewerInfo {
            display: flex;
            align-items: center;
            gap: 20px;

            textContent {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 5px;

              name {
                color: var(--131748, #131748);
                font-family: Figtree;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 111.111% */
              }
              job {
                color: var(--131748, #131748);
                font-family: Figtree;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 125% */

                opacity: 0.7;
              }
            }

            img {
              width: 60px;
              height: 60px;
              border-radius: 100%;
            }
          }
        }

        buttonWrapper {
          position: absolute;
          right: 0;
          bottom: 13.3333px;
          display: flex;
          z-index: 100;
          gap: 13.3333px;

          .prev {
            cursor: pointer;
          }

          .next {
            transform: rotate(180deg);
            cursor: pointer;
          }
        }
      }
    }
  }
  section4 {
    display: inline-flex;
    padding: 80px 150px;
    align-items: flex-start;
    justify-content: center;
    gap: 122px;
    left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      max-width: 248px;
      h5 {
        cursor: pointer;
        transition: 0.3s;
        display: flex;
        align-items: center;
        gap: 10px;
        color: #131748;
        text-transform: none;
        square {
          width: 8px;
          height: 8px;
          background-color: #131748;
        }
      }
    }
    right {
      width: fit-content;
      display: block;
      height: 666.665px;
      max-width: 670px;

      tab {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 66.6665px;

        textContent {
          display: flex;
          flex-direction: column;
          gap: 13.3333px;

          acc {
            color: #131748;
          }
          h4 {
            max-width: 650px !important;
            text-transform: none;
          }
        }

        img {
          width: 100%;
          height: 466.66549999999995px;
          object-fit: cover;
        }
      }
    }
  }
  section5 {
    background-color: #111541;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding: 106.66666666666666px 133.33333333333331px;
    top {
      display: flex;
      justify-content: space-between;
      gap: 50px;
      p {
        width: 400px;
      }

      right {
        margin-right: 66.66666666666666px;
        width: 666.6666666666666px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        button {
          cursor: pointer;
          display: flex;
          padding: 22.666666666666664px 66.66666666666666px;
          justify-content: center;
          align-items: center;
          gap: 13.333333333333332px;
          color: var(--F1F4F6, #f1f4f6);
          background-color: #131748;
          text-align: center;
          font-family: "Inter Tight";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 21.333333333333332px; /* 133.333% */
          letter-spacing: 0.8px;
          text-transform: uppercase;
          border: 1px solid rgba(241, 244, 246, 0.5);
        }
      }
    }
    bottom {
      display: flex;
      // flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      gap: 50px;

      example {
        background-color: #31388f;
        height: 400px;
        display: flex;

        img {
          width: 50%;
          flex-shrink: 0;
          height: 100%;
          object-fit: cover;
        }

        textContent {
          position: relative;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          h5 {
            margin-bottom: 13.333333333333332px;
          }
          p {
            align-self: start;
          }

          button {
            cursor: pointer;
            display: flex;
            padding: 17px 50px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: var(--F1F4F6, #f1f4f6);
            text-align: center;
            font-family: "Inter Tight";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 133.333% */
            letter-spacing: 0.6px;
            text-transform: uppercase;
            background: transparent;
            border: 1px solid rgba(241, 244, 246, 0.5);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  homeWrapper {
    position: relative;
    left: unset;
    top: 70px;
    width: 100%;
    display: inline-flex;
    flex-direction: column;

    hero {
      position: relative;

      height: 100vh;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.4) 0%,
          rgba(0, 0, 0, 0.4) 100%
        ),
        url(../../assets/homePageHero.jpg) lightgray 50% / cover no-repeat;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: unset;
      padding: 15px;
      heroContent {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 6.66665px;
        acc {
          display: flex;
          gap: 13.3333px;
          align-items: center;
          color: #fff;
        }
        h1 {
          color: #fdfdfd;
          font-family: Figtree;
          font-size: 34px;
          font-style: normal;
          font-weight: 500;
          line-height: 40px; /* 117.647% */
          text-transform: uppercase;
          width: 100%;
        }
        button {
          margin-top: 19.99995px;
          padding: 22.66661px 66.6665px;
          background: var(--31388F, #31388f);
          cursor: pointer;
          color: var(--F1F4F6, #f1f4f6);
          text-align: center;
          font-family: "Inter Tight";
          font-size: 15.999959999999998px;
          font-style: normal;
          font-weight: 700;
          line-height: 21.33328px; /* 133.333% */
          letter-spacing: 0.7999979999999999px;
          text-transform: uppercase;
        }
      }
    }
    section2 {
      position: relative;

      display: flex;
      padding: 40px 15px;
      flex-direction: column;
      align-items: center;
      // gap: 30px;
      point {
        border-bottom: 1px solid #c2c4e1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        padding: 30px 0px;
        width: 100%;
        flex-direction: column;
        gap: 10px;
        left {
          width: 200px;
          flex-shrink: 0;
          align-items: center;
          display: flex;
          gap: 20px;
          img {
            width: 40.001px;
            height: 40px;
          }
        }
        p {
          width: 100%;
          max-width: 450px;
        }
        &:first-child {
          border-top: 1px solid #c2c4e1;
        }
      }
    }
    section3 {
      position: relative;

      display: flex;
      flex-direction: column;
      background-color: #f1f4f6;
      img {
        height: 650px;
        object-fit: cover;
      }

      reviewsWrapper {
        display: flex;
        padding: 40px 15px;
        flex-direction: column;
        align-items: flex-start;
        gap: 39px;

        h3 {
          width: 100%;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 20px;
        }

        theReviews {
          display: flex;
          align-items: flex-start;
          gap: 30px;
          align-self: stretch;
          flex-direction: column;
          svg {
            flex-shrink: 0;
          }
        }

        .swiperWrapper {
          position: relative;
          width: 100%;
          overflow-x: hidden;

          .review {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;

            width: 100%;
            reviewText {
              color: var(--131748, #131748);
              font-family: Figtree;
              font-size: 23px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px; /* 130.435% */
            }

            reviewerInfo {
              display: flex;
              align-items: center;
              gap: 20px;

              textContent {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;

                name {
                  color: var(--131748, #131748);
                  font-family: Figtree;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px; /* 111.111% */
                }
                job {
                  color: var(--131748, #131748);
                  font-family: Figtree;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px; /* 125% */

                  opacity: 0.7;
                }
              }

              img {
                width: 60px;
                height: 60px;
                border-radius: 100%;
              }
            }
          }

          buttonWrapper {
            position: absolute;
            right: 0;
            bottom: 13.3333px;
            display: flex;
            z-index: 100;
            gap: 13.3333px;

            .prev {
              cursor: pointer;
            }

            .next {
              transform: rotate(180deg);
              cursor: pointer;
            }
          }
        }
      }
    }
    section4 {
      display: flex;
      padding: 50px 15px;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;
      left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        max-width: 248px;
        h5 {
          cursor: pointer;
          transition: 0.3s;
          display: flex;
          align-items: center;
          gap: 10px;
          color: #131748;
          text-transform: none;
          square {
            width: 8px;
            height: 8px;
            background-color: #131748;
          }
        }
      }
      right {
        width: fit-content;
        display: block;
        height: 100%;
        max-width: 670px;

        tab {
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 30px;

          textContent {
            display: flex;
            flex-direction: column;
            gap: 10px;

            acc {
              color: #131748;
            }
            h4 {
              max-width: 100% !important;
              text-transform: none;
            }
          }

          img {
            width: 100%;
            height: 180px;
            object-fit: cover;
          }
        }
      }
    }
    section5 {
      background-color: #111541;
      color: #fff;
      display: flex;
      flex-direction: column;
      gap: 60px;
      padding: 50px 15px;
      top {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        flex-direction: column;
        width: 100%;

        p {
          width: 100%;
        }

        right {
          margin-right: unset;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 40px;
          button {
            cursor: pointer;
            display: flex;
            padding: 15px 40px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: var(--F1F4F6, #f1f4f6);
            text-align: center;
            font-family: "Inter Tight";
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 133.333% */
            letter-spacing: 0.6px;
            text-transform: uppercase;
            border: 1px solid rgba(241, 244, 246, 0.5);
          }
        }
      }
      bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 66.66666666666666px;

        example {
          background-color: #31388f;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }

          textContent {
            display: flex;
            height: 280px;
            padding: 20px;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            align-self: stretch;
            h5 {
              margin-bottom: 10px;
            }
            p {
              align-self: start;
            }

            button {
              cursor: pointer;
              display: flex;
              padding: 15px 40px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              color: var(--F1F4F6, #f1f4f6);
              text-align: center;
              font-family: "Inter Tight";
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px; /* 133.333% */
              letter-spacing: 0.6px;
              text-transform: uppercase;
              border: 1px solid rgba(241, 244, 246, 0.5);
              background: var(--31388F, #31388f);
            }
          }
        }
      }
    }
  }
}
