* {
  margin: 0;
  padding: 0;
  border: 0;
  user-select: none
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Figtree;
  font-style: normal;
  font-weight: 600;
}
h1 {
  font-size: 56px;
  line-height: 58px;
}
h2 {
  font-size: 42px;
  line-height: 48px;
}
h3 {
  font-size: 36px;
  line-height: 41px;
}
h4 {
  font-size: 28px;
  line-height: 32px;
}
h5 {
  font-size: 22px;
  line-height: 26px;
}
h6 {
  font-size: 19px;
  line-height: 22px;
}

p {
  font-family: Figtree;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 153.333% */
}

acc {
  color: var(--F1F4F6, #f1f4f6);
  font-family: Figtree;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.98px;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 46px;
    line-height: 50px;
  }
  h3 {
    font-size: 32px;
    line-height: 36px;
  }
  h4 {
    font-size: 22px;
    line-height: 26px;
  }
  h5 {
    font-size: 20px;
    line-height: 24px;
  }
  h6 {
    font-size: 17px;
    line-height: 20px;
  }
}
