privacy {
  position: relative;
  left: 90px;
  width: calc(100% - 290px);
  display: inline-flex;
  flex-direction: column;
  padding: 40px;
}

@media screen and (max-width: 768px) {
  privacy {
    position: relative;
    top: 70px;
    left: 0px;
    width: calc(100% - 90px);
    display: inline-flex;
    flex-direction: column;
    padding: 40px;
    margin-bottom: 70px;
  }
}
