contactWrapper {
  width: 100%;
  display: flex;

  iframe {
    width: 50%;
    flex-shrink: 0;
  }
  thankYou {
    width: 50%;

    display: flex;
    padding: 80px 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    align-self: stretch;
    height: 50vh;
    text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 11px;
      align-self: stretch;
      h4 {
        color: var(--131748, #131748);
        text-align: center;

        /* Heading 4 */
        font-family: Figtree;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 114.286% */
      }
      p {
        color: var(--31388F, #31388f);
        text-align: center;

        /* Paragraph */
        font-family: Figtree;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px; /* 153.333% */
      }
    }
    a {
      display: flex;
      padding: 17px 50px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border: 1px solid rgba(241, 244, 246, 0.5);
      background: var(--131748, #131748);
      color: var(--F1F4F6, #f1f4f6);
      text-align: center;
      font-family: "Inter Tight";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.6px;
      text-transform: uppercase;
      text-decoration: none;
      transition: all 0.1s;

      &:hover {
        background: #f1f4f6;
        color: var(--131748, #131748);
        border: solid 1px #131748;
        transition: all 0.5s;
      }
    }
  }
  Form {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 106.66666666666666px 133.33333333333331px;

    h4 {
      color: var(--131748, #131748);
      text-align: center;

      /* Heading 4 */
      font-family: Figtree;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 114.286% */
      padding-bottom: 11px;
    }

    p {
      color: var(--31388F, #31388f);
      text-align: center;
      margin-bottom: 40px;
    }

    acc {
      color: var(--131748, #131748);

      /* Accent */
      font-family: Figtree;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.98px; /* 157% */
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    input {
      transition: border 0.5s;
      margin-bottom: 26.666666666666664px;
      padding: 20px 0;
      border-bottom: 1px solid var(--131748, #131748);
      flex: 1 0 0;

      color: var(--131748, #131748);

      /* Paragraph */
      font-family: Figtree;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30.666666666666664px; /* 153.333% */

      &::placeholder {
        flex: 1 0 0;

        color: var(--131748, #131748);

        /* Paragraph */
        font-family: Figtree;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30.666666666666664px; /* 153.333% */

        opacity: 0.6;
      }
    }

    button {
      display: flex;
      padding: 17px 50px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      color: var(--F1F4F6, #f1f4f6);
      text-align: center;
      font-family: "Inter Tight";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.6px;
      text-transform: uppercase;
      border: 1px solid rgba(241, 244, 246, 0.5);
      background: var(--131748, #131748);
      transition: 0.1s;

      &:hover {
        background: transparent;
        color: #131748;
        border: 1px solid #131748;
        transition: 0.5s;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  contactWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    iframe {
      width: 100%;
      height: 405px;
      flex-shrink: 0;
    }
    thankYou {
      width: calc(100% - 30px);
      display: flex;
      padding: 50px 15px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
      align-self: stretch;
      height: 50vh;
      text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 11px;
        align-self: stretch;
        h4 {
          color: var(--131748, #131748);
          text-align: center;
          
          /* Mobile/Heading 4 */
          font-family: Figtree;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px; /* 118.182% */
        }
        p {
          color: var(--31388F, #31388F);
          text-align: center;
          
          /* Mobile/Paragraph */
          font-family: Figtree;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 160% */
        }
      }
      a {
        display: flex;
        padding: 17px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border: 1px solid rgba(241, 244, 246, 0.5);
        background: var(--131748, #131748);
        color: var(--F1F4F6, #F1F4F6);
        text-align: center;
        font-family: "Inter Tight";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.6px;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.1s;

        &:hover {
          background: #f1f4f6;
          color: var(--131748, #131748);
          border: solid 1px #131748;
          transition: all 0.5s;
        }
      }
    }
    Form {
      width: calc(100% - 30px);
      height: 100%;
      display: flex;
      padding: 50px 15px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-bottom: 70px;

      h4 {
        color: var(--131748, #131748);
        text-align: center;

        /* Heading 4 */
        font-family: Figtree;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 114.286% */
        padding-bottom: 11px;
        width: 100%;
      }

      p {
        color: var(--31388F, #31388f);
        text-align: center;
        margin-bottom: 40px;
        width: 100%;
      }

      acc {
        color: var(--131748, #131748);

        /* Accent */
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21.98px; /* 157% */
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: left;
        width: 100%;
      }

      input {
        width: 100%;
        transition: border 0.5s;
        margin-bottom: 26.666666666666664px;
        padding: 20px 0;
        border-bottom: 1px solid var(--131748, #131748);
        flex: 1 0 0;

        color: var(--131748, #131748);

        /* Mobile/Paragraph */
        font-family: Figtree;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */

        &::placeholder {
          flex: 1 0 0;

          color: var(--131748, #131748);

          /* Paragraph */
          font-family: Figtree;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 30.666666666666664px; /* 153.333% */

          opacity: 0.6;
        }
      }

      button {
        display: flex;
        padding: 17px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        color: var(--F1F4F6, #f1f4f6);
        text-align: center;
        font-family: "Inter Tight";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.6px;
        text-transform: uppercase;
        border: 1px solid rgba(241, 244, 246, 0.5);
        background: var(--131748, #131748);
        transition: 0.1s;

        &:hover {
          background: transparent;
          color: #131748;
          border: 1px solid #131748;
          transition: 0.5s;
        }
      }
    }
  }
}
