.singleBlogWrapper {
  position: relative;
  left: 90px;
  height: 100%;
  width: calc(100% - 290px);
  display: flex;
  flex-direction: column;
  padding: 100px 135px;
  justify-content: center;
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    width: calc(100% - 90px);
    .mainHeader {
      width: calc(100% - 90px);
      display: flex;
      max-width: 700px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      .blogInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        .contentInfo {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          .date {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            p {
              color: var(--31388F, #31388f);

              /* Accent */
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 21.98px; /* 157% */
              letter-spacing: 2px;
              text-transform: uppercase;
            }
          }
          h3 {
            color: var(--131748, #131748);

            /* Heading 3 */
            font-family: Figtree;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 41px; /* 113.889% */
          }
        }
        p {
          color: var(--131748, #131748);

          /* Paragraph */
          font-family: Figtree;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px; /* 153.333% */
        }
      }
      .authorInfo {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        align-self: stretch;
        .author {
          display: flex;
          align-items: center;
          gap: 10px;
          .authorImage {
            display: flex;
            width: 51px;
            height: 51px;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
          }
          .authorDetails {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 3px;
            .name {
              color: var(--131748, #131748);

              /* Accent */
              font-family: Figtree;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 21.98px; /* 157% */
              letter-spacing: 2px;
              text-transform: uppercase;
            }
            .position {
              color: var(--31388F, #31388f);

              /* Paragraph */
              font-family: Figtree;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: 23px; /* 153.333% */
            }
          }
        }
        p {
          color: var(--31388F, #31388f);

          /* Paragraph */
          font-family: Figtree;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px; /* 153.333% */
        }
      }
    }
    .headerImage {
      align-self: start;
      width: 100%;
      height: 500px;
      object-fit: cover;
    }
  }
  .contentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    justify-content: center;
    width: calc(100% - 90px);
    margin-top: 50px;
    .share {
      max-width: 700px;

      display: flex;
      width: 100%;

      padding-bottom: 30px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--Divider-Color, #e2e2e2);
      .tagLine {
        color: var(--Black, #1b1b1b);

        /* Tagline */
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 120% */
        letter-spacing: 0.2px;
        text-transform: uppercase;
      }
      .shareLinks {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
    .content {
      max-width: 700px;

      width: 100%;
      ul,
      ol {
        margin-left: 25px;
      }
      img {
        width: 100%;
        height: auto !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .singleBlogWrapper {
    position: relative;
    left: unset;
    height: 100%;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    padding: 50px 15px;
    justify-content: center;
    top: 90px;
    margin-bottom: 90px;
    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      width: 100%;
      .mainHeader {
        width: 100%;

        display: flex;
        max-width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        .blogInfo {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          align-self: stretch;
          .contentInfo {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            .date {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              p {
                color: var(--31388F, #31388f);

                /* Accent */
                font-family: Figtree;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 21.98px; /* 157% */
                letter-spacing: 2px;
                text-transform: uppercase;
              }
            }
            h3 {
              color: var(--131748, #131748);

              /* Heading 3 */
              font-family: Figtree;
              font-size: 36px;
              font-style: normal;
              font-weight: 500;
              line-height: 41px; /* 113.889% */
            }
          }
          p {
            color: var(--131748, #131748);

            /* Paragraph */
            font-family: Figtree;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px; /* 153.333% */
          }
        }
        .authorInfo {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          align-self: stretch;
          .author {
            display: flex;
            align-items: center;
            gap: 10px;
            .authorImage {
              display: flex;
              width: 51px;
              height: 51px;
              justify-content: center;
              align-items: center;
              border-radius: 100%;
            }
            .authorDetails {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 3px;
              .name {
                color: var(--131748, #131748);

                /* Accent */
                font-family: Figtree;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 21.98px; /* 157% */
                letter-spacing: 2px;
                text-transform: uppercase;
              }
              .position {
                color: var(--31388F, #31388f);

                /* Paragraph */
                font-family: Figtree;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px; /* 153.333% */
              }
            }
          }
          p {
            color: var(--31388F, #31388f);

            /* Paragraph */
            font-family: Figtree;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px; /* 153.333% */
          }
        }
      }
      .headerImage {
        align-self: start;
        width: 100%;
        height: 500px;
        object-fit: cover;
      }
    }
    .contentDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      justify-content: center;
      width: 100%;
      margin-top: 50px;
      .share {
        max-width: 700px;

        display: flex;
        width: 100%;

        padding-bottom: 30px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--Divider-Color, #e2e2e2);
        .tagLine {
          color: var(--Black, #1b1b1b);

          /* Tagline */
          font-family: Inter;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px; /* 120% */
          letter-spacing: 0.2px;
          text-transform: uppercase;
        }
        .shareLinks {
          display: flex;
          align-items: center;
          gap: 15px;
        }
      }
      .content {
        max-width: 700px;

        width: 100%;
        ul,
        ol {
          margin-left: 25px;
        }
        img {
          width: 100%;
          height: auto !important;
        }
      }
    }
  }
}
