contactWrapperPage {
  height: 100vh;
  position: relative;
  left: 90px;
  width: calc(100% - 90px);
  display: flex;
  iframe {
    width: 50%;
    flex-shrink: 0;
  }

  Form {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 106.66666666666666px 133.33333333333331px;

    h4 {
      color: var(--131748, #131748);
      text-align: center;

      /* Heading 4 */
      font-family: Figtree;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 114.286% */
      padding-bottom: 11px;
    }

    p {
      color: var(--31388F, #31388f);
      text-align: center;
      margin-bottom: 40px;
    }

    acc {
      color: var(--131748, #131748);

      /* Accent */
      font-family: Figtree;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.98px; /* 157% */
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    input {
      transition: border 0.5s;
      margin-bottom: 26.666666666666664px;
      padding: 20px 0;
      border-bottom: 1px solid var(--131748, #131748);
      flex: 1 0 0;

      color: var(--131748, #131748);

      /* Paragraph */
      font-family: Figtree;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30.666666666666664px; /* 153.333% */

      &::placeholder {
        flex: 1 0 0;

        color: var(--131748, #131748);

        /* Paragraph */
        font-family: Figtree;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30.666666666666664px; /* 153.333% */

        opacity: 0.6;
      }
    }

    button {
      display: flex;
      padding: 17px 50px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      color: var(--F1F4F6, #f1f4f6);
      text-align: center;
      font-family: "Inter Tight";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.6px;
      text-transform: uppercase;
      border: 1px solid rgba(241, 244, 246, 0.5);
      background: var(--131748, #131748);
      transition: 0.1s;

      &:hover {
        background: transparent;
        color: #131748;
        border: 1px solid #131748;
        transition: 0.5s;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  contactWrapperPage {
    position: relative;
    left: 0;
    top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    iframe {
      width: 100%;
      flex-shrink: 0;
    }

    Form {
      width: calc(100% - 30px);
      height: 100%;
      display: flex;
      padding: 50px 15px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      margin-bottom: 70px;

      h4 {
        color: var(--131748, #131748);
        text-align: center;

        /* Heading 4 */
        font-family: Figtree;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 114.286% */
        padding-bottom: 11px;
        width: 100%;
      }

      p {
        color: var(--31388F, #31388f);
        text-align: center;
        margin-bottom: 40px;
        width: 100%;
      }

      acc {
        color: var(--131748, #131748);

        /* Accent */
        font-family: Figtree;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 21.98px; /* 157% */
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: left;
        width: 100%;
      }

      input {
        width: 100%;
        transition: border 0.5s;
        margin-bottom: 26.666666666666664px;
        padding: 20px 0;
        border-bottom: 1px solid var(--131748, #131748);
        flex: 1 0 0;

        color: var(--131748, #131748);

        /* Mobile/Paragraph */
        font-family: Figtree;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */

        &::placeholder {
          flex: 1 0 0;

          color: var(--131748, #131748);

          /* Paragraph */
          font-family: Figtree;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 30.666666666666664px; /* 153.333% */

          opacity: 0.6;
        }
      }

      button {
        display: flex;
        padding: 17px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        color: var(--F1F4F6, #f1f4f6);
        text-align: center;
        font-family: "Inter Tight";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.6px;
        text-transform: uppercase;
        border: 1px solid rgba(241, 244, 246, 0.5);
        background: var(--131748, #131748);
        transition: 0.1s;

        &:hover {
          background: transparent;
          color: #131748;
          border: 1px solid #131748;
          transition: 0.5s;
        }
      }
    }
  }
}
